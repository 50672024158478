import { CONTACT_SECTION_ID, HOW_IT_WORKS_SECTION_ID } from 'components/landing-page/constants'

export enum Route {
  Admin = '/admin',
  Athlete = '/athletes/:slug:',
  AthleteAccountSettings = '/athlete/account-settings',
  Athletes = '/athletes',
  FresnoAthletes = '/athletes/fresno',
  Base = '/',
  Book = '/book',
  BookClinic = '/book-clinic',
  ChangePassword = '/change-password',
  Clinic = '/clinic/:slug:',
  Clinics = '/clinics',
  CoachingPreferences = '/athlete/coaching-preferences',
  Contact = `/#${CONTACT_SECTION_ID}`,
  CookiePolicy = '/cookie-policy',
  AccessibilityStatement = '/accessibility-statement',
  CreateAccount = '/create-account',
  Dashboard = '/dashboard',
  Error = '/error',
  ForgotPassword = '/forgot-password',
  HowItWorks = `/#${HOW_IT_WORKS_SECTION_ID}`,
  Member = '/member',
  MemberAccountSettings = '/member/account-settings',
  Messages = '/messages',
  ProfileSettings = '/athlete/profile-settings',
  ResetPassword = '/reset-password',
  SignIn = '/sign-in',
  SetAvailability = '/athlete/set-availability',
  Calendar = '/calendar',
  PayBooking = '/pay-booking',
  PaymentSuccess = '/payment-success',
  Terms = '/terms',
  Review = '/review',
  PrivacyPolicy = '/privacy-policy',
  CancellationPolicy = '/cancellation-policy',
  MemberCalendar = 'member-calendar',
  MyMembershipsMember = '/my-memberships-member',
  MyMembershipsAthlete = '/my-memberships-athlete',
  ManageMembership = '/manage-membership',
  CancelMembership = '/cancel-membership',
}

export const resolveRoute = ({
  route,
  queryParams,
  pathParams,
}: {
  route: Route
  queryParams?: Record<string, string>
  pathParams?: Record<string, string>
}) => {
  let actualUrl = route.toString()

  if (pathParams) {
    Object.keys(pathParams).forEach((param) => {
      const paramRegex = new RegExp(`:${param}:`, 'g')
      actualUrl = actualUrl.replace(paramRegex, String(pathParams[param]))
    })
  }

  const url = new URL(`${process.env.NEXT_PUBLIC_APP_URL}${actualUrl}`)

  if (queryParams) {
    url.search = new URLSearchParams(queryParams).toString()
  }

  return url.toString()
}

export const SET_AVAILABILITY_BACK_URL = `${Route.SetAvailability}?back=${Route.Calendar}`
