import { AthleteStatus } from '@prisma/client'
import { DateRange } from 'api/integrations/storyblok/clinic/types'
import { SbAtheletePass } from 'api/integrations/storyblok/types'
import { MyAvailabilityQuery, TimeSlot } from 'apollo/generated/graphqlClient'
import { type ClassValue, clsx } from 'clsx'
import { eachDayOfInterval, format, isSameDay, subWeeks } from 'date-fns'
import { flatten } from 'lodash'
import { twMerge } from 'tailwind-merge'
import { StepState, StepStateType } from 'types/athlete'
import { DateFormat } from 'constants/common/dates'
import { Route } from 'constants/common/routes'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const formatDate = (date: Date) => {
  const formatter = Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
    year: 'numeric',
    hour12: true,
  })

  return formatter.format(date)
}

export const removeNonDigits = (input: string) => input.replace(/\D/g, '')

export const slugify = (str: string) =>
  String(str)
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-') // remove consecutive hyphens

export const resolveStepStatus = (
  athleteStatus: AthleteStatus,
  currentStep: AthleteStatus,
): StepStateType => {
  const athleteStatusMapper = {
    createdAccount: 1,
    completedProfile: 2,
    filledPreferences: 3,
    setAvaibility: 4,
    signedContract: 5,
    uploadedContract: 6,
    createdStripe: 7,
    inReview: 8,
    reviewed: 9,
    deactivated: 10,
  }

  const athleteStatusIndex = athleteStatusMapper[athleteStatus]
  const currentStepIndex = athleteStatusMapper[currentStep]
  if (athleteStatusIndex >= currentStepIndex) {
    return StepState.COMPLETED
  }
  if (athleteStatusIndex === currentStepIndex - 1) {
    return StepState.ACTIVE
  }
  return StepState.DISABLED
}

export const getFirstName = (fullName: string) => fullName.split(' ')[0]

export const getLastName = (fullName: string) => fullName.split(' ').pop()

export const generatePlaceholderUrl = (src: string, size = 10) => `${src}/m/${size}x0`

export const formatURL = (url: string) => (url.startsWith('http') ? url : `https://${url}`)

// ha - h: 12-hour clock, a: am/pm
// example: 10am => 10:00
export const convertTo24HourTimeFormat = (timeString: string) =>
  format(new Date(timeString), 'HH:mm')

export const parseEventDate = (date: DateRange) => {
  const startDate = new Date(date.from)
  const endDate = new Date(date.to)

  const isSingleDayEvent = isSameDay(startDate, endDate)

  if (isSingleDayEvent) {
    return format(startDate, DateFormat.Default)
  }

  return `${format(startDate, "MMMM d , yyyy 'at' h:mm aa")}`
}

export const isTimeSlotOverlapping = (newTimeSlot: string, timeSlotArray: string[]) => {
  const timeToMinutes = (time: string) => {
    const hoursToMinutes = Number(time.split(':')[0]) || 24
    return hoursToMinutes * 60 + Number(time.split(':')[1])
  }

  return timeSlotArray.find(
    (item) => Math.abs(timeToMinutes(item) - timeToMinutes(newTimeSlot)) < 60,
  )
}

// convert military time to US time format
export const convertTimeToReadableFormat = (timeString: string) => {
  // Split the time string into hours and minutes
  const [hours, minutes] = timeString.split(':').map(Number)

  // Determine AM or PM
  const meridiem = hours >= 12 ? 'PM' : 'AM'

  // Convert hours to 12-hour format
  const displayHours = hours % 12 || 12

  // Create the formatted time string
  const formattedTime = `${displayHours}:${minutes.toString().padStart(2, '0')} ${meridiem}`

  return formattedTime
}

export const bookingTypeMapper = {
  single: 'One-on-one',
  group: 'Group training',
  athletePass: 'Athlete pass',
} as const

export const isFulfilled = <T>(
  input: PromiseSettledResult<T>,
): input is PromiseFulfilledResult<T> => input.status === 'fulfilled'

export const trainingLocation = {
  same: 'same-location',
  variable: 'variable-location',
}

export const toUTCMidnight = (localDate: Date) => {
  // Converts a given local date to UTC midnight and returns it as an ISO string.
  // Adjusts for the local timezone offset to ensure the time is 00:00:00.000Z in UTC.

  localDate.setHours(0, 0, 0, 0)
  const utcMidnight = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000)
  return utcMidnight.toISOString()
}

export const getUpdatedAvailableSlots = ({
  availableTimeslot,
  newDaysOff,
}: {
  availableTimeslot: MyAvailabilityQuery['myAvailability']['availableSlots']
  newDaysOff: TimeSlot[]
}) => {
  const allDaysOff = flatten(
    newDaysOff.map((slot) =>
      eachDayOfInterval({
        start: new Date(slot.startTime as string),
        end: new Date(slot.endTime as string),
      }),
    ),
  )

  const newAvailableTimeslots = availableTimeslot.map((slot) => {
    const isDayOff = allDaysOff.some((day) => isSameDay(day, new Date(slot.startTime as string)))

    return {
      ...slot,
      isDayOff,
    }
  })

  return newAvailableTimeslots
}

export const isProduction = (): boolean => process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'prod'

export const generateTimeSlotsBetween = (startTime: string, endTime: string) => {
  const timeSlots = []

  const startDate = new Date(`2024-01-01 ${startTime}`)
  const endDate = new Date(`2024-01-01 ${endTime}`)

  endDate.setHours(endDate.getHours() - 1)

  const currentSlot = startDate
  while (currentSlot <= endDate) {
    const formattedTime = currentSlot.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: false,
    })
    timeSlots.push(formattedTime)

    currentSlot.setHours(currentSlot.getHours() + 1)
  }

  return timeSlots
}

export const addOneHour = (timeString: string) => {
  const parts = timeString.split(':')
  let hours = parseInt(parts[0])
  const minutes = parseInt(parts[1])

  hours = (hours + 1) % 24

  const newTimeString = `${(hours < 10 ? '0' : '') + hours}:${minutes < 10 ? '0' : ''}${minutes}`

  return newTimeString
}

// temporarily hardcoded
export const isAthletePassFavorite = (numOfSessions: number, discount: number) =>
  numOfSessions === 10 && discount === 10

export const findBiggestDiscount = (athletePasses: SbAtheletePass[]) =>
  athletePasses.reduce(
    (maxDiscount, pass) => (pass.discount > maxDiscount ? pass.discount : maxDiscount),
    athletePasses[0].discount,
  )

export const formatMessageTime = (time: Date) => {
  const now = new Date()

  const yesterday = new Date(now)
  yesterday.setDate(yesterday.getDate() - 1)

  const weekAgo = subWeeks(now, 1)

  if (time.toDateString() === yesterday.toDateString()) {
    return 'Yesterday'
  }
  if (time > yesterday) {
    return time.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    })
  }
  if (time > weekAgo) {
    return time.toLocaleDateString('en-US', {
      weekday: 'long',
    })
  }
  const formatter = Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
  })

  return formatter.format(time)
}

const isAthleteOrClinicDetailPathname = (pathname: string) => {
  if (pathname.startsWith('/athlete')) {
    if (
      [
        Route.AthleteAccountSettings,
        Route.CoachingPreferences,
        Route.ProfileSettings,
        Route.SetAvailability,
      ]
        .map((route) => String(route))
        .includes(pathname)
    ) {
      return false
    }
    return true
  }
  if (pathname.startsWith('/clinic')) {
    return true
  }
  return false
}

export const shouldNotificationBarBeShown = (pathname: string) =>
  [
    Route.Athletes,
    Route.FresnoAthletes,
    Route.Base,
    Route.Book,
    Route.ChangePassword,
    Route.Clinics,
    Route.CookiePolicy,
    Route.AccessibilityStatement,
    Route.ForgotPassword,
    Route.ResetPassword,
    Route.SignIn,
    Route.Terms,
    Route.PrivacyPolicy,
    Route.CancellationPolicy,
  ]
    .map((route) => String(route))
    .includes(pathname) || isAthleteOrClinicDetailPathname(pathname)

export const formatDuration = (durationMins: number) =>
  durationMins > 60
    ? `${Math.floor(durationMins / 60)} hours ${durationMins % 60} minutes`
    : `${durationMins} minutes`

export const padStartZero = (val: number | string) => val.toString().padStart(2, '0')
