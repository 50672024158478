import { createContext, ReactNode, useEffect, useState } from 'react'
import { ApolloProvider } from '@apollo/client'
import { TooltipProvider } from '@radix-ui/react-tooltip'
import ably from 'ably'
import { useApollo } from 'hooks/useApollo'
import { useSetSentryUser } from 'hooks/useSetSentryUser'
import { cn, isProduction } from 'lib/utils'
import { NextPageContext } from 'next'
import type { AppProps } from 'next/app'
import { Outfit } from 'next/font/google'
import localFont from 'next/font/local'
import Head from 'next/head'
import Script from 'next/script'
import { getSession, SessionProvider, useSession } from 'next-auth/react'
import { appWithTranslation } from 'next-i18next'
import { BaseAppProps, NextPageWithLayout } from 'types/next'
import { REFETCH_INTERVAL } from 'constants/common/auth'
import { Toaster } from 'components/ui/toaster'
import '../../global.css'

/*
 Uncomment for SSG
const getStaticProps = makeStaticProps(['common'])
export { getStaticPaths, getStaticProps }
 */

type AppPropsWithLayout = AppProps<BaseAppProps> & {
  Component: NextPageWithLayout<BaseAppProps>
}

const prohibition = localFont({
  src: '../../public/fonts/ProhibitionTest-Regular.otf',
  variable: '--font-prohibition',
})

const outfit = Outfit({ subsets: ['latin'] })

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const apolloClient = useApollo(pageProps.initialApolloState)
  const getLayout = Component.getLayout ?? ((page) => page)
  useSetSentryUser()

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
      </Head>
      <main className={cn(outfit.className, prohibition.variable)}>
        <Toaster />
        {isProduction() && (
          <>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-NBFSX3GTCR" />
            <Script id="ga4-init" strategy="afterInteractive">
              {`           
       window.dataLayer = window.dataLayer || [];
       function gtag(){dataLayer.push(arguments);}
       gtag('js', new Date());
       
       gtag('config', 'G-NBFSX3GTCR');
       `}
            </Script>

            <Script id="ga4" strategy="afterInteractive">
              {`           
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-P34JHFD5');
      `}
            </Script>
          </>
        )}
        <ApolloProvider client={apolloClient}>
          <TooltipProvider>{getLayout(<Component {...pageProps} />)}</TooltipProvider>
        </ApolloProvider>
      </main>
    </>
  )
}

const AppWithI18n = appWithTranslation(App)

export const AblyContext = createContext<ably.Realtime | undefined>(undefined)

const AblyProvider = ({ children }: { children: ReactNode }) => {
  const [ablyInstance, setAblyInstance] = useState<ably.Realtime>()

  const session = useSession()

  useEffect(() => {
    setAblyInstance(
      new ably.Realtime({
        authUrl: '/api/chat-auth',
      }),
    )
  }, [session])

  return <AblyContext.Provider value={ablyInstance}>{children}</AblyContext.Provider>
}

const AppWithAuth = (props: AppPropsWithLayout) => {
  const { pageProps } = props
  return (
    <SessionProvider session={pageProps.session} refetchInterval={REFETCH_INTERVAL}>
      <AblyProvider>
        <AppWithI18n {...props} />
      </AblyProvider>
    </SessionProvider>
  )
}

AppWithAuth.getInitialProps = async (ctx: NextPageContext) => {
  const session = await getSession(ctx)
  return { pageProps: { session } }
}

export default AppWithAuth
