import { ObjectValues } from './common'

export const StepState = {
  COMPLETED: 'COMPLETED',
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const

export type StepStateType = ObjectValues<typeof StepState>

export const YearsOfExperience = {
  '0-1YEAR': '0-1',
  '1YEAR': '1',
  '2YEARS': '2',
  '3YEARS': '3',
  '4YEARS': '4',
  '5+YEARS': '5+',
} as const

export type YearsOfExperienceType = ObjectValues<typeof YearsOfExperience>
